import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { paperPlane } from 'react-icons-kit/fa/paperPlane';
import { ic_phone } from 'react-icons-kit/md/ic_phone';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import {
    ContactInfo,
    InfoItem,
} from './footer.style';
import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';
import { contactInfo } from '../../data/index';

const ContactUs = ( {
    sectionWrapper,
    textArea,
    buttonArea,
    buttonStyle,
    title,
    description,
} ) => {
    return (
        <Box { ...sectionWrapper } as="section">
            <Container>
                <NewsletterWrapper>
                    {/*<Box { ...textArea }>
            <Heading content="Need help? We are here for you" { ...title } />
            <Text
              content="siddharth.marwaha@askiitians.com"
              { ...description }
            />
            <Text
              content="8860961007"
              { ...description }
            />
          </Box>*/}
                    <ContactInfo>
                        <Heading content="Need help? We are here for you" { ...title } />
                        {/*<InfoItem>
              <Icon icon={ ic_phone } size={ 26 } className="phone-icon" />
              <div>
                <Text
                  { ...description }
                  content={ contactInfo.contactInfo.phone }
                />
              </div>
            </InfoItem>
            <InfoItem>
              <Icon icon={ paperPlane } size={ 22 } />
              <Text content={ contactInfo.contactInfo.email } { ...description } />
            </InfoItem>*/}
                    </ContactInfo>
                    <Box { ...buttonArea }>

                        <InfoItem>
                            <Icon icon={ paperPlane } size={ 22 } />
                            <Text content={ contactInfo.contactInfo.email } { ...description } />
                        </InfoItem>

                        <InfoItem>
                            <Icon icon={ ic_phone } size={ 26 } className="phone-icon" />
                            <div>
                                <Text
                                    { ...description }
                                    content={ contactInfo.contactInfo.phone }
                                />
                            </div>
                        </InfoItem>
                        {/*<ContactFormWrapper>
              <Button { ...buttonStyle } title="Contact Us" />
            </ContactFormWrapper>*/}
                    </Box>
                </NewsletterWrapper>
            </Container>
        </Box>
    );
};

ContactUs.propTypes = {
    sectionWrapper: PropTypes.object,
    textArea: PropTypes.object,
    buttonArea: PropTypes.object,
    buttonStyle: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
};

ContactUs.defaultProps = {
    sectionWrapper: {},
    textArea: {
        mb: ['40px', '40px', '40px', '0', '0'],
        pr: ['0', '0', '0', '80px', '100px'],
    },
    title: {
        fontSize: ['18px', '20px', '22px', '24px', '26px'],
        fontWeight: '500',
        color: '#fff',
        lineHeight: '1.34',
        mb: ['14px', '14px', '14px', '14px', '33px'],
        textAlign: ['center', 'center', 'center', 'left', 'left'],
        letterSpacing: '-0.025em',
        mb: '5px',
    },
    description: {
        fontSize: ['14px', '14px'],
        fontWeight: '400',
        color: '#fefefe',
        lineHeight: '1.7',
        mb: 0,
        textAlign: ['center', 'center', 'center', 'left', 'left'],
    },
    buttonArea: {
        flexBox: true,
        flexDirection: 'column',
        zIndex: 1,
    },
    buttonStyle: {
        type: 'button',
        fontSize: '14px',
        fontWeight: '700',
        pl: '30px',
        pr: '30px',
        colors: 'secondaryWithBg',
    },
};

export default ContactUs;
