import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import { FOOTER_DATA } from '../../data/index';
import React from 'react';
import FooterArea from './footer.style';
import { Image } from 'antd';

const Footer = () => {
    const { logo, menu, social } = FOOTER_DATA;
    return (
        <FooterArea>
            <Container>
                <Box className="logoBox">
                    <a className="logo">
                        <Image src={ logo } alt="logo footer" />
                    </a>
                    {/*<Text as="p" content={ `Copyright © ${ new Date().getFullYear() }` } />
          <a className="footerLink">RedQ, Inc.</a>*/}
                </Box>
                {/*<Box className="menu">
          { menu.map( ( { link, label }, index ) => (
            <a className="menuLink">{ label }</a>
          ) ) }
        </Box>*/}
                <Box className="social">
                    <Text as="span" content="Copyright © 2024 All Rights Reserved to Global Careers. Analytics Powered by Stride Ahead" />
                </Box>
            </Container>
        </FooterArea>
    );
};

export default Footer;
