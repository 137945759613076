import styled, { keyframes } from 'styled-components';
import { style } from 'styled-system';

export const NavbarWrapper = styled.section `
  padding: 70px 0 0 0;
  background-image: linear-gradient(180deg, #294686 0%, #6692CB 38%, #6895CE 94%, white 99%);
  background-size: cover;
  background-position: top center;
  overflow: hidden;
  @media (max-width: 575px) {
    padding: 50px 0 0 0;
  }
`;

export const BoxWrapper = styled.div `
display : flex;
width:100%;
justify-content:center;
align-items:center;
gap : 35rem;

@media (max-width: 575px) {
  display : flex;
  flex-direction : column;
  gap:5px;
  justify-content : center;
  align-items : center;  
  }

`

export const MainStickyWrapper = styled.div `
  display: flex;
  justify-content: space-between;
  background: #DB494A;
  padding: 1rem 5rem;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

export const MainHeading = styled.p `
  font-size: 20px;
  font-weight: 700;
  margin: 0px;
  text-align: center;
  color: white;

  @media (max-width: 700px) {
    font-size: 18px;
    margin-bottom: 1rem;
  }
`;

export const MainCtaButton = styled.button `
  padding: 1rem 1.5rem;
  color: #DB494A;
  border-radius: 10px;
  background-color : #FFFFFF;
  border : unset; 
  font-size: 18px;
  font-weight: 700;
cursor : pointer;

  @media (max-width: 700px) {
    width: 100%;
    text-align: center;
  }
`;